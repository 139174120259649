export const MENU = {
    'home': {
        name: 'Home',
        description: "Return to safe zone",
        link: '/home'
    },
    // 'project': {
    //     name: 'Project',
    //     description: "View all our works",
    //     link: '/project'
    // },
    'contact': {
        name: 'Contact',
        description: "Get in touch and find us",
        link: '/contact'
    },
}
